import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section">
      <h1>Where to find us</h1>
      <p>Find us at Kiosk K, Mount Pleasant Centre, Mount Pleasant 4740.</p>  
      <p>Email us at <a href='mailto:mastercobblersmackay@gmail.com'>mastercobblersmackay@gmail.com</a></p>
      <p>Call us on <a href="tel:+61467158594">0467 158 594</a>.</p>
      <iframe width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kiosk%20K,%20Mount%20Pleasant%20Center,%20Mount%20Pleasant,%20Mackay,%204740.+(Master%20Cobblers)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    </ContactStyles>
  );
}

export default Contact
